import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
    PropertyContext,
    useExportSettings,
    usePatchExportSettings,
    VaudRivieraSettings,
    useVaudRivieraReportRequest,
} from '../api';
import { DatePicker, TextField, Checkbox, Switch } from '../components';
import { Base, PageParams } from './Base';
import { NotFound } from './NotFound';

export function VaudRiviera(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    //const subscription = React.useContext(SubscriptionContext);
    const [state, setState] = React.useState<VaudRivieraSettings>(
        {} as VaudRivieraSettings,
    );
    const [date, setDate] = React.useState<Date | null>(new Date());
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    const {
        getReport,
        state: reportState,
        error: reportError,
    } = useVaudRivieraReportRequest(
        property ? property.code : '',
        state.vaudriviera_id ?? '',
    );
    const { data: initialData, state: initialDataState } =
        useExportSettings<VaudRivieraSettings>('vaudriviera', id);
    const {
        patch,
        state: patchState,
        data: patchData,
    } = usePatchExportSettings<VaudRivieraSettings>('vaudriviera', id);
    React.useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData]);
    React.useEffect(() => {
        if (patchData) {
            setState(patchData);
        }
    }, [patchData]);
    if (!property) {
        return <NotFound />;
    }
    return (
        <Base
            loading={
                initialDataState === 'loading' ||
                initialDataState === 'initial' ||
                reportState === 'loading'
            }
        >
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-col border border-black rounded bg-white p-2">
                <div className="flex flex-row">
                    <h1 className="font-bold">Vaud Riviera</h1>
                    <Switch
                        checked={state.enabled}
                        onChange={(v) => {
                            setState({ ...state, enabled: v });
                            patch({ enabled: v });
                        }}
                        disabled={patchState === 'loading'}
                    >
                        Enable
                    </Switch>
                </div>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Hotel CD"
                        value={state.vaudriviera_id}
                        onChange={(v) =>
                            setState({ ...state, vaudriviera_id: v })
                        }
                        onBlur={() =>
                            patch({ vaudriviera_id: state.vaudriviera_id })
                        }
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <Checkbox
                    checked={state.enable_emails}
                    onChange={(v) => {
                        setState({ ...state, enable_emails: v });
                        patch({ enable_emails: v });
                    }}
                    disabled={!state.enabled || patchState === 'loading'}
                >
                    Automatically send daily
                </Checkbox>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Send mail to"
                        value={state.email_to}
                        onChange={(v) => setState({ ...state, email_to: v })}
                        onBlur={() => patch({ email_to: state.email_to })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Send mail cc"
                        value={state.email_cc ?? ''}
                        onChange={(v) => setState({ ...state, email_cc: v })}
                        onBlur={() => patch({ email_cc: state.email_cc })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Filename"
                        value={state.fileName ?? ''}
                        onChange={(v) => setState({ ...state, fileName: v })}
                        onBlur={() => patch({ fileName: state.fileName })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <span className={state.enabled ? '' : 'text-gray-500'}>
                    Download XSLX
                </span>
                <div className="p-2 w-1/2">
                    <div>
                        <DatePicker
                            value={date}
                            onChange={(date) => setDate(date)}
                            label="Date:"
                        />
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled || reportState === 'loading'
                            }
                            onClick={() => getReport(date)}
                        >
                            <span className="mr-2">Export</span>
                        </button>
                    </div>
                    {reportState === 'error' && (
                        <span className="text-red-500">{reportError}</span>
                    )}
                </div>
            </div>
        </Base>
    );
}
