import * as React from 'react';
import { useParams } from 'react-router-dom';
import {
    PropertyContext,
    useNwReportRequest,
    NwSettings,
    useExportSettings,
    usePatchExportSettings,
    SubscriptionContext,
} from '../api';
import { Switch, DatePicker, TextField, Checkbox } from '../components';
import { Base, PageParams } from './Base';
import { NotFound } from './NotFound';

export function NwExport(): JSX.Element {
    const { properties } = React.useContext(PropertyContext);
    const subscription = React.useContext(SubscriptionContext);
    const [state, setState] = React.useState<NwSettings>({} as NwSettings);
    const [date, setDate] = React.useState<Date | null>(new Date());
    const { id } = useParams<PageParams>();
    const property = properties.find((p) => p.code === id);
    const {
        getReport,
        state: reportState,
        error: reportError,
    } = useNwReportRequest(property ? property.code : '');
    const { data: initialData, state: initialDataState } =
        useExportSettings<NwSettings>('nw', id);
    const {
        patch,
        state: patchState,
        data: patchData,
    } = usePatchExportSettings<NwSettings>('nw', id);
    React.useEffect(() => {
        if (initialData) {
            setState(initialData);
        }
    }, [initialData]);
    React.useEffect(() => {
        if (patchData) {
            setState(patchData);
        }
    }, [patchData]);
    if (!property) {
        return <NotFound />;
    }
    return (
        <Base
            loading={
                initialDataState === 'loading' ||
                initialDataState === 'initial' ||
                reportState === 'loading'
            }
        >
            <h1 className="font-bold text-3xl">{property.name}</h1>
            <div className="flex flex-col border border-black rounded bg-white p-2">
                <div className="flex flex-row">
                    <h1 className="font-bold mr-2">Guest Control NW</h1>
                    <Switch
                        checked={state.enabled && subscription.nw.subscription}
                        onChange={(v) => {
                            if (!subscription.nw.subscription) {
                                subscription.nwCheckoutRedirect();
                            } else {
                                setState({ ...state, enabled: v });
                                patch({ enabled: v });
                            }
                        }}
                        disabled={patchState === 'loading'}
                    >
                        Subscribe
                    </Switch>
                </div>
                <div className="w-1/2 p-2">
                    <TextField
                        name="Property Id"
                        value={state.nw_id}
                        onChange={(v) => setState({ ...state, nw_id: v })}
                        onBlur={() => patch({ nw_id: state.nw_id })}
                        disabled={!state.enabled || patchState === 'loading'}
                    />
                </div>
                <Checkbox
                    checked={state.enable_sftp}
                    onChange={(v) => {
                        setState({ ...state, enable_sftp: v });
                        patch({ enable_sftp: v });
                    }}
                    disabled={!state.enabled || patchState === 'loading'}
                >
                    Automatically send daily
                </Checkbox>
                <div className="w-1/2 p-2">
                    <TextField
                        name="SFTP username"
                        value={state.username}
                        onChange={(v) => setState({ ...state, username: v })}
                        onBlur={() => patch({ username: state.username })}
                        disabled={
                            !state.enabled ||
                            !state.enable_sftp ||
                            patchState === 'loading'
                        }
                    />
                    <TextField
                        name="SFTP password"
                        value={state.password}
                        onChange={(v) => setState({ ...state, password: v })}
                        onBlur={() => patch({ password: state.password })}
                        disabled={
                            !state.enabled ||
                            !state.enable_sftp ||
                            patchState === 'loading'
                        }
                    />
                    <TextField
                        name="SFTP path"
                        value={state.path ?? ''}
                        onChange={(v) => setState({ ...state, path: v })}
                        onBlur={() => patch({ path: state.path })}
                        disabled={
                            !state.enabled ||
                            !state.enable_sftp ||
                            patchState === 'loading'
                        }
                    />
                </div>
                <span className={state.enabled ? '' : 'text-gray-500'}>
                    Download CSV
                </span>
                <div className="p-2 w-1/2">
                    <div>
                        <DatePicker
                            value={date}
                            onChange={(date) => setDate(date)}
                            label="Date:"
                        />
                        <button
                            className="bg-gray-200 rounded border border-gray-500 disabled:text-gray-500 py-2 px-4 flex flex-row items-center justify-center mt-2"
                            disabled={
                                !state.enabled || reportState === 'loading'
                            }
                            onClick={() => getReport(date)}
                        >
                            <span className="mr-2">Export</span>
                        </button>
                    </div>
                    {reportState === 'error' && (
                        <span className="text-red-500">{reportError}</span>
                    )}
                </div>
            </div>
        </Base>
    );
}
