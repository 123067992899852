import * as React from 'react';

export interface CheckboxProps {
    className?: string;
    children?: React.ReactNode;
    checked?: boolean;
    onChange: (checked: boolean) => void;
    disabled?: boolean;
}

export function Checkbox({
    checked,
    children,
    onChange,
    disabled,
}: CheckboxProps) {
    return (
        <label>
            <input
                type="checkbox"
                checked={!!checked}
                onChange={(e) => onChange(e.target.checked)}
                disabled={!!disabled}
            />{' '}
            {children}
        </label>
    );
}
